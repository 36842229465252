@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
/*Font*/

/*Reset*/
* {
    padding: 0;
    border: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

body {
  background: #fff;
  background: var(--bg);
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  --bg: #fff;
  --text: #fff;
  --textP: #021338;
  --principal: #021338;
}

img {
  display: block;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}

input,button {
  font-family: 'Poppins', sans-serif;
}

.popup-overlay {
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  background-color: rgba(0,0,0,0.5);
}

.mobile {
  display: none;
}

.pc {
  display: block;
}

@media (max-width: 991px) {
  .mobile {
    display: block;
  }

  .pc {
    display: none;
  }
}

/*Scroll*/

::-webkit-scrollbar {
  display: none;
  }
  
  #scrollPath {
    position: fixed;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
  }
  
  
  #progessbar
  {
    position: fixed;
    top: 0;
    right: 0;
    width: 10px;
    background: linear-gradient(to top, #008aff, #00ffe7);
    animation: animate 5s linear infinite;
  }
  
  @keyframes animate {
    0%, 100%
    {
        -webkit-filter: hue-rotate(0deg);
                filter: hue-rotate(0deg);
    }
  
    50%
    {
        -webkit-filter: hue-rotate(360deg);
                filter: hue-rotate(360deg);
    }
  }
  
  
  #progessbar:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #008aff, #00ffe7);
    -webkit-filter: blur(30px);
            filter: blur(30px);
  }

  .disabled {
    display: none;
  }

  #pc {
    display: flex;
  }

  #mobile {
    display: none;
  }

  @media (max-width: 991px) {
    #pc {
      display: none;
    }
  
    #mobile {
      display: block;
    }
  }

  /*About*/

.about {
  background: #fff;
  background: var(--bg);
  padding: 200px;
}

.contentAbout {
  display: flex;
}

.titleAbout h1 {
  color: #021338;
  color: var(--textP);
}

.titleAbout p {
  color: #021338;
  color: var(--textP);
  margin-top: 3%;
  margin-right: 30%;
}

.borderAbout {
  height: 360px;
  width: 1px;
  background: #021338;
  background: var(--textP);
}

.destaquesAbout {
  display: flex;
}

.contentDestaquesBox {
  display: flex;
  margin-top: 15%;
}

.contentDestaquesBox h1 {
  color: #021338;
  color: var(--principal);
  font-size: 20px;
}

.contentDestaquesBox p {
  color: #021338;
  color: var(--textP);
  margin-left: 5%;
  margin-top: 7%;
}

.destaquesBox {
  padding: 20px;
}

.boxNumberDestaques {
  width: 45px;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 6px;
}

@media (min-width: 1025px) {
  .titleAbout {
    margin-top: 3%;
  }

  .destaquesAbout {
    transform: translateX(-70%);
  }
}

@media (max-width: 768px) {

  .about {
    padding: 50px;
  }

  .contentAbout {
    display: grid;
  }

  .titleAbout {
    display: flex;
    justify-content: center;
  }
  
  .titleAbout h1 {
    margin-right: 0%;
  }

  .titleAbout p {
    margin-right: 0%;
  }

  .destaquesAbout {
    display: grid;
  }

  .destaquesBox {
    display: grid;
    justify-content: left;
    margin-top: 5%;
  }

  .contentDestaquesBox {
    display: flex;
    margin-top: 15%;
}

  .borderAbout {
    height: 2px;
    width: 650px;
    margin-top: 10%;
  }

}

@media (max-width: 600px) {

  .about {
    padding: 50px;
  }

  .contentAbout {
    display: grid;
  }

  .titleAbout {
    display: flex;
    justify-content: center;
  }
  
  .titleAbout h1 {
    margin-right: 0%;
  }

  .titleAbout p {
    margin-right: 0%;
  }

  .destaquesAbout {
    display: grid;
  }

  .destaquesBox {
    display: grid;
    justify-content: left;
    margin-top: 5%;
  }

  .contentDestaquesBox {
    display: flex;
    margin-top: 15%;
}

  .borderAbout {
    height: 2px;
    width: 300px;
    margin-top: 10%;
  }

}

.clientes {
  background: #021338;
  background: var(--principal);
  padding: 100px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.content-clientes {
  width: 100%;
  height: 75px;
  border-radius: 20px;
  background: #fff;
  background: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-slider-clientes {
  margin-top: 3%;
}

.carousel-container-clientes {
  height: 150px;
  position: relative;
  max-width: 1450px;
  overflow: hidden;
  touch-action: pan-x;
}

.content-clientes h1 {
  color: #021338;
  color: var(--textP);
  font-size: 30px;
}

.carousel-card-clientes {
  width: 150px;
  height: 120px;
  background: #fff;
  background: var(--bg);
  margin-left: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.carousel-card-clientes:hover {
  opacity: .8;
  transition: .8s ease;
}

.carousel-card-inner-clientes {
  flex-direction: column;
  width: 150px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-img img {
  width: 150px;
  height: 120px;
  border-radius: 20px;
}

.title-clientes {
  display: flex;
  justify-content: center;
}


@media (max-width: 600px) {
  .clientes {
      padding: 50px;
  }
  .content-clientes {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .carousel-card-inner-clientes {
      width: 130px;
      height: 105px;
  }
  .title-clientes {
      margin-top: 0%;
  }
  .content-clientes h1 {
      font-size: 20px;
  }
  .content-box-cliente {
      margin-top: 8%;
  }
  .carousel-container-clientes {
      height: 135px;
  }
  
  .box-cliente {
      margin-left: 0%;
      margin-top: 8%;
  }
  .carousel-container-clientes {
      max-width: 500px;
  }
  .carousel-card-clientes {
      margin-left: 2.3%;
      width: 130px;
      height: 105px;
  }
  .carousel-img img {
      width: 100px;
      height: 100px;
  }
  .container-slider-clientes {
      margin-top: 8%;
  }
}

.contato {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-info-contato {
  margin-top: 1%;
  margin-right: 25%;
}

.box-title-contato {
  width: 100%;
  height: 100px;
  border-radius: 20px;
  background: #021338;
  background: var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.box-title-contato i {
  color: #fff;
  color: var(--text);
  margin-right: 2.5%;
  font-size: 28px;
  margin-left: 3%;
}

.box-title-contato p {
  color: #fff;
  color: var(--text);
  margin-right: 5%;
  font-size: 13px;
}

.box-content-info-contato {
  display: flex;
  align-items: center;
  margin-top: 10%;
}

.box-content-info-contato i {
  margin-right: 10%;
  color: #021338;
  color: var(--textP);
  font-size: 30px;
}

.box-content-info-contato h1 {
  color: #021338;
  color: var(--textP);
  font-size: 30px;
}

.box-content-info-contato p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
}

.input-contanto {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.content-button-contato {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.content-input-contato {
  margin-top: 3%;
}

.content-input-contato form {
  display: grid;
}

.content-input-contato form input,textarea {
  margin-top: 5%;
  width: 260px;
  height: 40px;
  outline: none;
  border: 2px solid #021338;
  border: 2px solid var(--principal);
  border-radius: 10px;
  padding: 5%;
  color: #021338;
  color: var(--textP);
  font-family: 'Poppins';
}

.content-input-contato form textarea {
  height: 80px;
}

.content-input-contato form input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #021338;
  color: var(--textP);
}

.content-input-contato form input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #021338;
  color: var(--textP);
}

.content-input-contato form input::placeholder,textarea::placeholder {
  color: #021338;
  color: var(--textP);
}

.buttonContato {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 35px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
  margin-right: 3%;
}

.buttonContato i {
  margin-right: 5%;
  font-size: 12px;
}

.buttonContato a {
  color: #fff;
  color: var(--text);
  font-size: 10px;
}

#buttonContatoSolicitar-whats i {
  color: #3EB595;
}

#buttonContatoSolicitar-gmail i {
  color: #812F33;
}

@media (max-width: 991px) {
  .input-contanto {
      display: grid;
      justify-content: center;
  }
  .content-input-contato{
      margin-top: 10%;
  }
}

@media (max-width: 600px) {
  .box-title-contato {
      width: 330px;
  }

  .box-title-contato i {
      margin-right: 5%;
  }

  .box-title-contato p {
      font-size: 10px;
  }
}

.produto-input-solicitar {
  display: flex;
  align-items: center;
}

.produto-input-solicitar  i {
  color: #021338;
  color: var(--textP);
  font-size: 20px;
  margin-left: 150%;
  margin-top: 55%;
}

.dropdown-solicitar {
  display: grid;
  justify-content: center;
  align-items: center;
}

/*Sobre Empresas*/


.sobreInfo {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.contentInfo {
  display: flex;
  justify-content: center;
}


.infoboxInfo {
  padding: 20px;
}

.infoboxInfo h1 {
  color: #fff;
  color: var(--text);
  font-size: 25px;
}

.infoboxInfo p {
  color: #fff;
  color: var(--text);
  margin-top: 10%;
  font-size: 16px;
}

.boxInfo {
  width: 300px;
  height: 800px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
}

.imgInfo {
  display: grid;
  margin-left: 5%;
  margin-top: 3%;
}

.imgInfo-01 img {
  width: 520px;
  height: 350px;
  border-radius: 20px;
}

.imgInfo-02 img {
  width: 520px;
  height: 350px;
  border-radius: 20px;
  margin-top: 10%;
}

.videoInfo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.infoVideo {
  display: flex;
  justify-content: center;
  padding: 20px
}

.boxInfoVideo {
  width: 850px;
  height: 310px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
}

.boxInfoVideo h1 {
  color: #fff;
  color: var(--text);
  text-align: center;
  margin-top: 2%;
  font-size: 25px;
}

.boxInfoVideo p {
  color: #fff;
  color: var(--text);
  padding: 20px;
}

.infoTutorial {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.boxInfoTutorial {
  width: 850px;
  height: 480px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
}

.boxInfoTutorial h1 {
  color: #fff;
  color: var(--text);
  font-size: 22px;
  text-align: center;
  padding: 20px;
}

.tutorialInfo {
  display: flex;
  margin-left: 5%;
  margin-top: 3%
}

.videoTutoriaInfo-1 {
  margin-right: 5%;
}

.buttonTutorialInfo {
  display: flex;
  justify-content: center;
  width: 400px;
  height: 40px;
  background: #fff;
  background: var(--text);
  border-radius: 20px;
  margin-left: 25%;
  margin-top: 3%;
}

.buttonTutorialInfo a {
  color: #021338;
  color: var(--principal);
  padding: 5px;
  font-size: 18px;
}

.buttonTutorialInfo i {
  color: #021338;
  color: var(--principal);
  padding: 5px;
  font-size: 18px;
  margin-top: 1%;
}

.infoSolicitar {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.boxinfoSolicitar {
  width: 600px;
  height: 50px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
}

.infoSolicitarAgora {
  display: flex;
  justify-content: center;
  padding: 10px
}

.infoSolicitarAgora a {
  font-size: 20px;
}

.infoSolicitarAgora i {
  color: #fff;
  color: var(--text);
  margin-right: 2%;
  font-size: 18px;
  margin-top: 0.8%;
}

@media (max-width: 991px) {

  .solicitarCompra {
    display: none;
  }

  .contentInfo {
    display: grid;
    justify-content: center;
  }

  .imgInfo {
    display: grid;
    margin-left: 8%;
    margin-top: 3%;
  }
  
  .imgInfo-01 img {
    width: 340px;
    height: 250px;
    border-radius: 20px;
  }
  
  .imgInfo-02 img {
    width: 340px;
    height: 250px;
    border-radius: 20px;
    margin-top: 10%;
  }

  .videoInfo {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .infoVideo {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .boxInfoVideo {
    width: 850px;
    height: 650px;
    background: #021338;
    background: var(--principal);
    border-radius: 20px;
  }
  
  .boxInfoVideo h1 {
    color: #fff;
    color: var(--text);
    text-align: center;
    margin-top: 5%;
    font-size: 25px;
  }
  
  .boxInfoVideo p {
    color: #fff;
    color: var(--text);
    padding: 25px;
  }

  .infoTutorial {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .boxInfoTutorial {
    width: 350px;
    height: 850px;
    background: #021338;
    background: var(--principal);
    border-radius: 20px;
  }
  
  .boxInfoTutorial h1 {
    color: #fff;
    color: var(--text);
    font-size: 18px;
    text-align: center;
    padding: 20px;
  }

  
  
  .buttonTutorialInfo {
    display: flex;
    justify-content: center;
    width: 300px;
    height: 40px;
    background: #fff;
    background: var(--text);
    border-radius: 20px;
    margin-top: 3%;
    margin-left: 5%;
  }
  
  .buttonTutorialInfo a {
    color: #021338;
    color: var(--principal);
    padding: 5px;
    font-size: 18px;
  }
  
  .buttonTutorialInfo i {
    color: #021338;
    color: var(--principal);
    padding: 5px;
    font-size: 18px;
    margin-top: 1%;
  }

  .tutorialInfo {
    display: grid;
    justify-content: center;
    margin-left: -1%;
  }

  .videoTutoriaInfo-1 iframe{
    width: 320px; 
    height: 300px;
  }

  .videoTutoriaInfo-2 iframe{
    width: 320px; 
    height: 300px;
  }

}

/*Footer*/

.content-footer {
  display: grid;
  justify-content: center;
  align-items: center;
}

.footer{
  background: #021338;
  background: var(--principal);
  color: #fff;
  color: var(--text);
  padding: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-tecnologias {
  background: #021338;
  background: var(--principal);
  color: #fff;
  color: var(--text);
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-border-footer {
  display: flex;
  justify-content: center;
}

.border-footer {
  height: 2px;
  background: #fff;
  background: var(--bg);
}

.contato-footer {
  margin-top: -25%;
}

.title-footer {
  margin-top: -7%;
}

.item {
  margin-left: -10%;
}

.item h1 {
  color: #fff;
  color: var(--text);
  font-size: 30px;
}

.item p {
  color: #fff;
  color: var(--text);
  font-size: 12px;
  margin-top: 1%;
}


.itemLink h1 {
  color: #fff;
  color: var(--text);
  font-size: 30px;
}

.contentItemLink {
  display: grid;
}

.contentItemLink a {
  margin-top: 3%;
}

.box-social-media-footer {
  display: flex;
  align-items: center;
}

.box-social-media-footer i {
  color: #fff;
  color: var(--text);
  margin-right: 10%;
}

.title-tecnologias-footer {
  display: flex;
  justify-content: center;
}

.title-tecnologias-footer h1 {
  font-size: 20px;
}

.content-img-footer {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.box-img-footer-1, .box-img-footer-2, .box-img-footer-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.box-img-footer-1 img {
  width: 50px;
  height: 35px;
}

.box-img-footer-2 img {
  width: 50px;
  height: 50px;
}

.box-img-footer-3 img {
  width: 80px;
  height: 30px;
}

.input-tecnologias-footer {
  display: flex;
  justify-content: center;
}

.content-input-tecnologias-footer {
  text-align: center;
}

.content-input-footer {
  display: flex;
  justify-content: center;
}

.input-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.content-input-footer input {
  width: 250px;
  height: 35px;
  outline: none;
  color: #021338;
  color: var(--textP);
  padding: 3%;
  margin-right: 3%;
  border-radius: 10px;
}

.content-input-footer input::-webkit-input-placeholder {
  color: #021338;
  color: var(--textP);
}

.content-input-footer input:-ms-input-placeholder {
  color: #021338;
  color: var(--textP);
}

.content-input-footer input::placeholder {
  color: #021338;
  color: var(--textP);
}

.title-tecnologias-footer {
  margin-top: 10%;
}

.content-footer-tecnologias {
  display: flex;
  justify-content: center;
  align-items: center;
}

.endereco-footer-tecnologias {
  text-align: center;
}

@media(max-width: 991px) {
  .input-tecnologias-footer h1 {
    font-size: 20px;
  }
  .input-footer {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

.input-footer input {
  margin-top: 5%;
}


.content-img-footer {
  display: grid;
  justify-content: center;
  margin-top: 5%;
}

.content-img-footer img {
  margin-top: 15%;
}

.box-img-footer-3 img {
  width: 250px;
}

.footer-tecnologias {
  background: #021338;
  background: var(--principal);
  color: #fff;
  color: var(--text);
  height: 100%;
  padding: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-tecnologias iframe {
  width: 300px;
  height: 400px;
}

.itemLink-2{
  margin-top: 30%;
  margin-right: 0%;
}

.content-button-send-input-footer {
  width: 250px;
  height: 35px;
  background: #fff;
  background: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  border-radius: 10px;
}

.content-button-send-input-footer i  {
  color: #021338;
  color: var(--textP);
  font-size: 14px;
}

}

@media (max-width: 1100px) {
  .footer {
    display: flex;
    padding: 150px;
  }
  .item {
    margin-left: 0%;
    margin-right: 5%;
    margin-top: 5%;
  }
  .itemLink {
    margin-top: 5%;
    margin-right: 8%;
  }
}

@media (max-width: 991px) {
  .footer {
    display: grid;
    justify-content: center;
    padding: 80px;
  }
  .item {
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 0%;
  }
  .itemLink {
    margin-top: 5%;
    margin-right: 0%;
  }
}



.slider {
  padding: 100px;
}

.sliderImg {
  margin-top: -3%;
}

.sliderMobile {
  display: none;
}

@media (max-width: 1024px) {

  .slider {
    padding: 70px;
  }

  .sliderPC {
    display: none;
  }
  .sliderMobile {
    display: flex;
    justify-content: center;
  }
  .sliderImg {
    height: 100vh;
    min-width: 600px;
  }

}

@media (max-width: 768px) {

  .slider {
    padding: 60px;
  }

  .sliderPC {
    display: none;
  }
  .sliderMobile {
    display: flex;
    justify-content: center;
  }
  .sliderImg {
    height: 100vh;
    min-width: 600px;
  }

}

@media (max-width: 600px) {
  .slider {
    padding: 50px;
  }

  .sliderPC {
    display: none;
  }
  .sliderMobile {
    display: flex;
    justify-content: center;
  }
  .sliderImg {
    height: 80vh;
    min-width: 340px;
  }
}

/*Login*/

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title-input-login h1 {
  color: #021338;
  color: var(--textP);
  font-size: 35px;
  text-align: center;
}

.title-input-login p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  text-align: center;
}

.border-login {
  width: 330px;
  height: 1px;
  background: #021338;
  background: var(--principal);
  margin-top: 5%;
}


.input-login {
  display: grid;
  justify-content: center;
  margin-top: 5%;
}

.content-input-login {
  margin-top: 5%;
}

.button-login button {
  background: none;
  color: #fff;
  color: var(--text);
  font-size: 14px;
  cursor: pointer;
}

.content-button-login {
  display: flex;
  justify-content: center;
}

.content-input-login p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
}

.content-input-login input {
  width: 300px;
  height: 35px;
  border-radius: 15px;
  border: 1.5px solid #021338;
  border: 1.5px solid var(--principal);
  color: #021338;
  color: var(--textP);
  padding: 10px;
  margin-top: 1%;
}


.content-input-login input::-webkit-input-placeholder {
  color: #697179;
}


.content-input-login input:-ms-input-placeholder {
  color: #697179;
}


.content-input-login input::placeholder {
  color: #697179;
}

.button-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 35px;
  margin-top: 10%;
  background: #021338;
  background: var(--principal);
  border-radius: 15px;
}


.button-login a {
  font-size: 14px;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  background: #021338;
  background: var(--principal);
  color: #fff;
  color: var(--text);
}

.logo img {
  width: 150px;
  height: 80px;
  padding: 5px;
}


@media(max-width: 600px) {

  .content-login {
    padding: 30px;
  }

  .login {
    padding: 0px;
    margin-left: 0%;
  }

  .boxLogin {
    width: 330px;
    height: 450px;
  }

  .titleLogin {
    padding: 10px;
  }

  .titleLogin h1 {
    font-size: 25px;
  }

  .titleLogin p {
    font-size: 10px;
  }

  .contentLogin {
    display: grid;
    justify-content: center;
  }

  .inputLogin {
    margin-left: 0%;
  }

  .inputLogin input {
    width: 250px;
    margin-left: 0%;
  }

  .inputLogin p {
    margin-top: 5%;
  }

  .buttonLogin {
    display: flex;
    justify-content: center;
    margin-left: 0%;
  }
}


.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  width: 75%;
}

.nav-links li {
  font-size: 18px;
  color: #fff;
  color: var(--text);
  padding: 10px 12px;
  cursor: pointer;
}

.nav-links li:hover {
  opacity: .8;
}

.mobile-menu-icon {
  display: none;
}

.button-ebackup {
  margin-right: 1%;
}

.boxEbackup {
  background: none;
  width: 100px;
  height: 35px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  border: 2px #fff solid;
  border: 2px var(--text) solid;
}

.boxEbackup:hover {
  opacity: .8;
}

.boxEbackup li {
  color: #fff;
  color: var(--text);
  font-size: 13px;
}

.boxPortal {
  background: none;
  width: 155px;
  height: 35px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 7%;
  border: 2px #fff solid;
  border: 2px var(--text) solid;
}

.boxPortal:hover {
  opacity: .8;
}

.boxPortal li {
  color: #fff;
  color: var(--text);
  font-size: 13px;
}

.boxLoginNavbar {
  background: #fff;
  background: var(--bg);
  width: 100px;
  height: 35px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  margin-left: 10%;
}

.boxLoginNavbar li {
  color: #021338;
  color: var(--textP);
  font-size: 14px;
}

.loginM {
  display: none;
}


.buttonNavbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textButtonNav {
  color: #fff;
  color: var(--text);
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  font-size: 17px;
  margin-top: -3%;
  margin-right: 5%;
}

.buttonNavbar i {
  color: #fff;
  color: var(--text);
  font-size: 14px;
  transform: translateY(-20%) translateX(15%);
}

.explorarMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1%;
  cursor: pointer;
}

.explorarMenu:hover {
  opacity: .6;
}

.menuNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuNavbar svg {
  margin-left: -2%;
}

.menuOpenNavbar {
  display: block;
}

.contentMenuOpenNavbar {
  display: grid;
  background: #021338;
  background: var(--principal);
  width: 170px;
  padding: 25px;
  left: 1400px;
  position: relative;
  justify-content: left;
  text-align: left;
  align-items: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.listMenuOpenNav svg {
  color: #fff;
  color: var(--text);
  font-size: 14px;
  margin-right: 3%;
}

.contentMenuNavbar {
  margin-top: -20%;
}

.listMenuOpenNav {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 15%;
}

.listMenuOpenNav:hover {
  opacity: .8;
}

.listMenuOpenNav i {
  color: #fff;
  color: var(--text);
  font-size: 16px;
  margin-right: 10%;
  margin-top: -1%;
}

.homeNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homeNavbar:hover {
  opacity: .6;
}


@media (max-width: 2560px) {

  .sliderHeader {
      margin-top: 0%;
  }

  .container-slider {
      margin-top: 3%;
  }

  .contentMenuOpenNavbar  {
      left: 1780px;
  }
}


@media (max-width: 2000px) {

  .contentMenuOpenNavbar  {
      left: 1305px;
  }

  .container-slider {
      margin-top: 1%;
  }

}

@media (max-width: 1920px) {

  .contentMenuOpenNavbar  {
      left: 1235px;
  }

  .container-slider {
      margin-top: 1%;
  }

}

@media (max-width: 1920px) {

  .contentMenuOpenNavbar  {
      left: 1235px;
  }

  .container-slider {
      margin-top: 1%;
  }

}



@media (max-width: 1920px) {

  .contentMenuOpenNavbar  {
      left: 1235px;
  }

  .container-slider {
      margin-top: 1%;
  }

}

@media (max-width: 1600px) {
  .contentMenuOpenNavbar  {
      left: 818px;
  }
}

@media (max-width: 1024px) {

  .navbar {
      border-bottom: 1px solid #fff;
      border-bottom: 1px solid var(--text);
  }

  .contentMenuOpenNavbar  {
      left: 465px;
  }
}

@media (max-width: 991px) {
  .explorarMenu  {
      display: none;
  }
  .contentMenuOpenNavbar {
      display: none;
  }
}

.menuCloseNavbar {
  display: none;
}

@media (max-width: 768px) {

  .mobileDisabled {
      display: none;
  }

  .navbar {
      position: relative;
  }

  .logo {
      display: flex;
      position: absolute;
      left: 45px;
  }

  .nav-links {
      display: none;
  }

  .nav-links-mobile {
      display: flex;
      justify-content: center;
      background: #021338;
      background: var(--principal);
      transition: .8s ease;
      width: 110%;
      height: 100vh;
  }

  .content-menu-mobile {
      display: block;
      margin-left: -35%;
  }

  .link-mobile {
     display: flex;
      justify-content: center;
      padding: 15px;
      width: 200%;
      transition: .8s ease;
      color: #fff;
      color: var(--text);
      font-size: 18px;
  }

  .mobile-menu-icon {
      display: block;
      position: absolute;
      font-size: 28px;
      outline: none;
      background: none;
      border: none;
      top: 25px;
      right: 50px;
      transition: .8s ease;
  }

  .mobile-menu-icon i {
      color: #fff;
      color: var(--text);
      transition: .8s ease;
  }

  .loginM {
      display: block;
  }

  .boxLoginNavbar {
      display: none;
  }
  
  .boxEbackup {
      display: none;
  }

} 


@media (max-width: 600px) {

  .mobileDisabled {
      display: none;
  }

  .navbar {
      position: relative;
  }

  .logo {
      display: flex;
      position: absolute;
      left: 45px;
  }

  .nav-links {
      display: none;
  }

  .nav-links-mobile {
      display: flex;
      justify-content: center;
      background: #021338;
      background: var(--principal);
      transition: .8s ease;
      width: 100%;
      height: 100vh;
  }

  .content-menu-mobile {
      display: block;
      margin-left: -35%;
  }

  .link-mobile {
     display: flex;
      justify-content: center;
      padding: 15px;
      width: 160%;
      transition: .8s ease;
      color: #fff;
      color: var(--text);
      font-size: 18px;
  }

  .mobile-menu-icon {
      display: block;
      position: absolute;
      font-size: 28px;
      outline: none;
      background: none;
      border: none;
      top: 25px;
      right: 50px;
      transition: .8s ease;
  }

  .mobile-menu-icon i {
      color: #fff;
      color: var(--text);
      transition: .8s ease;
  }

  .loginM {
      display: block;
  }

  .boxLoginNavbar {
      display: none;
  }
  
  .boxEbackup {
      display: none;
  }

} 

.link-mobile {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #fff;
  border-bottom: 1px solid var(--text);
  align-items: center;
}

.link-mobile:hover {
  opacity: .8;
}

.link-mobile i {
  font-size: 16px;
  margin-right: 5%;
  color: #fff;
  color: var(--text);
}

li{
  list-style: none;
  line-height: 2;
}
a{
  text-decoration: none;
  color: #fff;
  color: var(--text);
}

.novidades {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-novidades h1 {
  color: #021338;
  color: var(--textP);
  font-size: 40px;
  text-align: center;
}

.content-novidades-verificado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-novidades-verificado i {
  color: #021338;
  color: var(--textP);
  margin-right: 1%;
}

.content-novidades-verificado p {
  color: #021338;
  color: var(--textP);
  text-align: center;
}

.content-novidades img {
  width: 800px;
  height: 800px;
  margin-top: 3%;
}

.content-button-novidades {
  display: flex;
  justify-content: center;
}

.button-novidades {
  width: 400px;
  height: 45px;
  background: #021338;
  background: var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 2%;
}

.button-novidades a {
  color: #fff;
  color: var(--text);
  font-size: 12px;
  margin-right: 2%;
}

.button-novidades i {
  color: #fff;
  color: var(--text);
  font-size: 10px;
}

@media (max-width: 768px) {
  .content-novidades img {
      width: 650px;
      height: 600px;
      margin-top: 1%;
  }
}

@media (max-width: 768px) {
  .content-novidades img {
      width: 330px;
      height: 400px;
      margin-top: 5%;
  }
  .content-novidades-verificado p {
      color: #021338;
      color: var(--textP);
      font-size: 12px;
  }
  .button-novidades {
      margin-top: 5%;
      width: 300px;
  }
}

/*Parceiros*/

.parceiros {
  background: #fff;
  background: var(--bg);
  padding: 130px;
  display: flex;
  justify-content: center;
}

.classesParceiros {
  text-align: center;
  background: #021338;
  background: var(--principal);
  padding: 10px;
  border-radius: 20px;
  width: 1500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classesParceiros i {
  margin-right: 1.5%;
  font-size: 20px;
  color: #fff;
  color: var(--text);
}

.classesParceiros h1 {
  color: #fff;
  color: var(--text);
  font-size: 23px;
}

@media (max-width: 991px) {

  .parceiros {
    padding: 20px;
  }

  .titleParceiros h1 {
    font-size: 25px;
  }
  
    .classesParceiros h1 {
      color: #fff;
      color: var(--text);
      font-size: 20px;
    }

}

.contentBoxParceiro {
  display: grid;
  justify-content: center;
  padding: 25px;
}

.parteBoxParceiro {
  display: flex;
}

#parteBoxParceiroNone2 {
  display: none;
}

#parteBoxParceiroNone1 {
  display: none;
}

#parteBoxParceiroNone3 {
  display: none;
}

#parteBoxParceiroNone4 {
  display: none;
}

#parteBoxParceiroNone5 {
  display: none;
}

#parteBoxParceiroNone6 {
  display: none;
}

.boxParceiros {
  width: 220px;
  height: 180px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
  margin-right: 2%;
  margin-top: 3%;
  transition: .8s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.boxParceiros i {
  color: #fff;
  color: var(--text);
  font-size: 80px;
}

.boxParceiros h1 {
  color: #fff;
  color: var(--text);
  font-size: 30px;
}

#boxHotline {
  background: #572EB2;
}

#boxGdoor {
  background: #FE6500;
}

#boxSgbr {
  background: #03202E;
}

#boxCompufour {
  background: #2d353b;
}

.boxParceiros img {
  display: flex;
  justify-content: center;
  width: 205px;
  height: 190px;
  padding: 30px;
  margin-top: -3%;
}


.boxParceiros2 img {
  display: flex;
  justify-content: center;
  width: 205px;
  height: 190px;
  object-fit: cover;
  border-radius: 20px;
}


.boxParceiros .sgbrParceiros img {
  width: 180px;
  height: 130px;
}

.contentButtonParceiros {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.buttonParceiros {
  display: flex;
  width: 300px;
  height: 40px;
  background: #fff;
  background: var(--bg);
  border-radius: 20px;
}

.buttonParceiros a {
  margin-top: 2%;
  margin-left: 35%;
  color: #021338;
  color: var(--textP);
  text-align: center;
}

.buttonParceiros i {
  margin-left: 35%;
  color: #021338;
  color: var(--textP);
  text-align: center;
  font-size: 12px;
  margin-left: 2%;
  margin-top: 13px;
}

@media (max-width: 991px) {

  .buttonParceiros {
    display: none;
  }

  .parteBoxParceiro {
    display: grid;
  }

  .titleParceiros {
    display: grid;
    justify-content: center;
    padding: 20px;
    margin-right: -15%;
    text-align: center;
    margin-left: -12%;
  }
  
  .contentBoxParceiro {
    display: grid;
  }
  
  .boxParceiros {
    margin-top: 5%;
  }
}

.content-prox-parceiros {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.prox-parceiros-button {
  width: 270px;
  height: 45px;
  background: #021338;
  background: var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.prox-parceiros-button a {
  color: #fff;
  color: var(--text);
  font-size: 14px;
}

.prox-parceiros-button i {
  color: #fff;
  color: var(--text);
  font-size: 11px;
  margin-left: 3%;
}

.principal {
  background: #021338;
  background: var(--principal);
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-content-principal p {
  color: #fff;
  color: var(--text);
  font-size: 14px;
}

.title-content-principal h1 {
  color: #fff;
  color: var(--text);
  font-size: 30px;
}

.content-text-principal {
  display: flex;
  justify-content: center;
}

.text-principal {
  color: #fff;
  color: var(--text);
  font-size: 20px;
  font-weight: 600;
  margin-top: 3%;

}

.content-box-slider-principal-mobile {
display: none;
}

.content-box-slider-principal {
  display: flex;
  justify-content: left;
  margin-left: 0%;
  margin-top: 3%;
}

.carousel-container {
  position: relative;
  height: 400px;
  max-width: 1730px;
  margin: 0 auto;
  overflow: hidden;
  touch-action: pan-y;
}

.content-box-videos-principal {
  display: flex;
  justify-content: left;
  margin-top: 3%;
}

.content-box-videos-principal-mobile {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.box-videos-principal-mobile iframe {
  width: 315px;
  height: 250px;
  border-radius: 20px;
  margin-top: 2%;
}

.box-videos-principal {
  margin-right: 1.5%;
}

.box-videos-principal iframe {
  width: 550px;
  height: 400px;
  border-radius: 20px;
}

.carousel-track {
  display: flex;
  height: 100%;
}

.carousel-card {
  flex: 0 0 300px;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
  margin-left: 3%;
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text p {
  font-size: 12px;
  color: #021338;
  color: var(--textP);
  text-align: left;
  padding: 10px;
}

.carousel-pagination-wrapper {
  display: none;
}

.carousel-pagination {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.5);
}

.carousel-pagination .current {
  border-radius: 0;
  background: white;
}

  
.carousel-card-inner {
  flex-direction: column;
  width: 550px;
  height: 400px;
  border-radius: 20px;
}


.carousel-video iframe {
  width: 550px;
  height: 402px;
  border-radius: 20px;
  margin-top: -0.5%;
}

.content-button-principal {
  display: flex;
  justify-content: center;
}

.button-principal {
    margin-top: 3%;
    width: 300px;
    height: 40px;
    background: #fff;
    background: var(--bg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.button-principal a {
    color: #021338;
    color: var(--textP);
    font-size: 12px;
    margin-right: 3%;
}

.button-principal i {
  color: #021338;
  color: var(--textP);
  font-size: 10px;
}

@media(max-width: 1500px) {
  .box-videos-principal iframe {
    width: 300px;
    height: 200px;
  }
}

@media (max-width: 991px) {
  .box-videos-principal {
    margin-right: 0%;
  }
  .principal {
    padding: 30px;
  }
  .title-content-principal p {
    font-size: 12px;
  }
  .title-content-principal h1 {
    font-size: 23px;
  }
  .text-principal {
    display: none;
  }

  .content-box-slider-principal {
    display: none;
  }

  .content-box-slider-principal-mobile {
    display: flex;
    width: 700px;
    margin-top: 5%;
  }
 
  .carousel-card-inner {
    flex-direction: column;
    width: 320px;
    height: 400px;
    border-radius: 20px;
  }  
  .carousel-video iframe {
    width: 320px;
    height: 402px;
    border-radius: 20px;
    margin-top: -0.5%;
  }
  .button-principal {
    margin-top: 10%;
  }

  .title-vantagens {
    display: block;
  }
  
    .title-vantagens h1 {
      color: #fff;
      color: var(--text);
    }
    
    .box-vantagens-principais {
      width: 310px;
      height: 330px;
      background: #fff;
      background: var(--bg);
      border-radius: 15px;
      margin-top: 5%;
      padding: 5px
    }
    
    .box-vantagens-principais h1 {
      color: #021338;
      color: var(--textP);
      font-size: 25px;
      margin-top: 3%;
    }
    
    .content-button-principal {
      margin-left: -1%;
    }
    
    .content-vantagem-principal p {
      color: #021338;
      color: var(--textP);
      font-size: 12px;
    }
    
    .title-vantagens h1 {
      color: #fff;
      color: var(--text);
      margin-top: 3%;
    }
    
    .vantagens-principal {
      display: flex;
      justify-content: center;
    }
    
    .classe-principal {
      margin-right: 3%;
      border-right: 1px solid #fff;
      border-right: 1px solid var(--text);
      margin-top: 1%;
    }
    
    .classe-principal2 {
      margin-right: 3%;
      margin-top: 1%;
    }
    
    .classe-principal {
      margin-left: 5%
    }
    
    .content-vantagem-principal {
      display: flex;
      align-items: center;
      margin-top: 1%;
    }
    
    .content-vantagem-principal i {
      color: #021338;
      color: var(--textP);
      font-size: 14px;
      margin-right: 3%;
    }
  
    .vantagens-principal {
      display: grid;
      justify-content: left;
    }
    .vantagens-principal {
      margin-top: 5%;
    }
    .classe-principal {
      margin-right: 3%;
      border-right: none;
      margin-top: 1%;
    }
}


.title-vantagens h1 {
  color: #fff;
  color: var(--text);
}

.box-vantagens-principais {
  width: 310px;
  height: 330px;
  background: #fff;
  background: var(--bg);
  border-radius: 15px;
  margin-top: 3%;
  padding: 5px;
  margin-right: 3%;
}

.title-vantagens {
  display: none;
}

.box-vantagens-principais h1 {
  color: #021338;
  color: var(--textP);
  font-size: 25px;
  margin-top: 3%;
}

.content-button-principal {
  margin-left: -1%;
}

.content-vantagem-principal p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
}

.title-vantagens h1 {
  color: #fff;
  color: var(--text);
  margin-top: 3%;
}

.vantagens-principal {
  display: flex;
  justify-content: center;
}

.classe-principal {
  margin-right: 3%;
  border-right: 1px solid #fff;
  border-right: 1px solid var(--text);
  margin-top: 1%;
}

.classe-principal2 {
  margin-right: 3%;
  margin-top: 1%;
}

.classe-principal {
  margin-left: 5%
}

.content-vantagem-principal {
  display: flex;
  align-items: center;
  margin-top: 1%;
}

.content-vantagem-principal i {
  color: #021338;
  color: var(--textP);
  font-size: 14px;
  margin-right: 3%;
}

@media(max-width: 1024px) {
  .iframe-principal-2 {
    display: none;
  }
  .iframe-principal-3 {
    display: none;
  }
}

@media(max-width: 991px) {

  
.title-vantagens {
  display: block;
}

  .title-vantagens h1 {
    color: #fff;
    color: var(--text);
  }
  
  .box-vantagens-principais {
    width: 310px;
    height: 330px;
    background: #fff;
    background: var(--bg);
    border-radius: 15px;
    margin-top: 5%;
    padding: 5px
  }
  
  .box-vantagens-principais h1 {
    color: #021338;
    color: var(--textP);
    font-size: 25px;
    margin-top: 3%;
  }
  
  .content-button-principal {
    margin-left: -1%;
  }
  
  .content-vantagem-principal p {
    color: #021338;
    color: var(--textP);
    font-size: 12px;
  }
  
  .title-vantagens h1 {
    color: #fff;
    color: var(--text);
    margin-top: 3%;
  }
  
  .vantagens-principal {
    display: flex;
    justify-content: center;
  }
  
  .classe-principal {
    margin-right: 3%;
    border-right: 1px solid #fff;
    border-right: 1px solid var(--text);
    margin-top: 1%;
  }
  
  .classe-principal2 {
    margin-right: 3%;
    margin-top: 1%;
  }
  
  .classe-principal {
    margin-left: 5%
  }
  
  .content-vantagem-principal {
    display: flex;
    align-items: center;
    margin-top: 1%;
  }
  
  .content-vantagem-principal i {
    color: #021338;
    color: var(--textP);
    font-size: 14px;
    margin-right: 3%;
  }

  .vantagens-principal {
    display: grid;
    justify-content: left;
  }
  .vantagens-principal {
    margin-top: 5%;
  }
  .classe-principal {
    margin-right: 3%;
    border-right: none;
    margin-top: 1%;
  }
}

/*Vendas*/

.vendas {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 200px;
}

.boxTitle {
  width: 1400px;
  height: 200px;
  background: #021338;
  background: var(--principal);
  border-radius: 30px;
}

.contentBoxTitleVendas {
  display: grid;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.boxTitleContent {
  width: 800px;
  height: 50px;
  background: #fff;
  background: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-top: 3%;
}

.boxTitleContent h1 {
  color: #021338;
  color: var(--principal);
  font-size: 28px;
}

.titleVendas {
  margin-top: 3%;
  display: flex;
  justify-content: center;
}

.titleVendas p {
  color: #fff;
  color: var(--text);
  font-size: 18px;
}

.content-box-vendas {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.box-vendas {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
}

.box-vendas i {
  color: #021338;
  color: var(--principal);
  font-size: 100px;
}



.content-box-venda {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.box-vendas p {
  font-size: 14px;
  color: #021338;
  color: var(--principal);
  margin-top: 6%;
  text-align: center;
}

.content-button-vendas {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.button-box-vendas {
  width: 100px;
  height: 30px;
  border-radius: 20px;
  background: #021338;
  background: var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width: 1390px) {
  .contentBoxTitle {
    display: flex;
    justify-content: center;
  }
  .vendas {
    padding: 80px;
  }
  .boxTitle {
    width: 1200px;
  }
  .content-box-vendas {
    margin-top: 10%;
  }
}

@media (max-width: 1100px) {
  .vendas {
    padding: 100px;
  }
  .contentVendas {
    display: flex;
    justify-content: center;
  }
  .boxTitle {
    width: 350px;
    height: 180px;
}
  .boxTitleContent {
    width: 300px;
  }
  .titleVendas p {
    font-size: 12px;
    margin-top: 5%;
  }
  .content-box-vendas {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 0%;
    margin-left: 10%;
  }
  .box-vendas {
    display: flex;
    justify-content: center;
    margin-top: 15%;
  }
  .content-box-venda p {
    font-size: 10px;
    margin-top: 10%;
  }
  .button-box-vendas {
    width: 100px;
    height: 30px;
  }
  .button-box-vendas a {
    font-size: 12px;
  }
}

/*Dashboard*/


.container {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
  /* grid-gap: 0.2rem; */
}


.nav_icon {
  display: none;
}

.nav_icon > i {
  font-size: 26px;
  color: #a5aaad;
}


.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: #a5aaad;
  font-size: 15px;
  font-weight: 700;
}

  
.navbar__left i {
  color: var(--branco);
  font-size: 30px;
}

.navbar__left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}

main {
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  padding: 20px 35px;
}

.main__title {
  display: flex;
  align-items: center;
}

.main__title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.main__greeting > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.main__greeting > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  margin: 20px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70px;
  padding: 25px;
  border-radius: 5px;
  background: var(--branco);
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px var(--branco);
}

.card_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_inner > span {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 50px;
}

.charts__left {
  padding: 25px;
  border-radius: 5px;
  background: var(--branco);
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px var(--branco);
}

.charts__left__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__left__title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__left__title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__left__title > i {
  color: var(--branco);
  font-size: 20px;
  background: #ffc100;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}


.charts__right {
  padding: 25px;
  border-radius: 5px;
  background: var(--branco);
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px var(--branco);
}

.charts__right__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__right__title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__right__title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__right__title > i {
  color: var(--branco);
  font-size: 20px;
  background: #39447a;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card3 {
  background: #d6d8d9;
  color: #3a3e41;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

/*  SIDEBAR STARTS HERE  */

#sidebar {
  background: #021338;
  background: var(--principal);
  grid-area: sidebar;
  overflow: hidden;
  padding: 20px;
  transition: all 0.5s;
}



.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--branco);
  margin-bottom: 30px;
  /* color: #E85B6B; */
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}


#sidebarIcon {
    padding: 20px;
    font-size: 25px;
}

.sidebar__link {
  color: var(--branco);
  padding: 8px;
  border-radius: 3px;
  margin-bottom: 5px;
  display: flex;
}

.sidebar__link:hover {
  background: rgba(255, 255, 255, 0.185);
}

.active_menu_link {
  background: var(--branco);
  color: #021338;
  color: var(--principal);
}

.sidebar__link > a {
  text-decoration: none;
  color: var(--branco);
  font-weight: 500;
}


.sidebar__link > i {
  margin-right: 10px;
  font-size: 18px;
  margin-top: 1%;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  height: 100vh;
  animation: slide .8s ease forwards;
  transform: translateX(-300px);
}



@keyframes slide{
  to {
   transform: translateX(0);
  }
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 0.2fr 2.2fr; */
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }

  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }

  .nav_icon {
    display: inline;
  }
}

@media only screen and (max-width: 855px) {
  .main__cards {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
}


.navbar__left h2 {
  color: var(--branco);
  font-weight: 600;
}



.logoSidebar {
  display: flex;
  margin-top: 0%;
  margin-left: 2%;
}

.logoSidebar a {
  margin-left: 5%;
}

.logoSidebar i {
  color: var(--branco);
  font-size: 25px;
  margin-right: 5%;
}

.contentLogoSidebar {
  display: flex;
  margin-left: 10%;
}

.contentLogoSidebar p {
  color: var(--branco);
  font-size: 16px;
  margin-top: 1%;
}

.contentLogoSidebar i {
  color: var(--branco);
  margin-left: 10%;
  margin-top: 12%;
  font-size: 12px;
}


/*Home Dashboard*/


.homeDashboard {
  padding: 20px;
}

.titleHomeDashboard {
  display: flex;
  justify-content: center;
}

.boxTitleHomeDashboard {
  background: #021338;
  background: var(--principal);
  border-radius: 25px;
  width: 900px;
}

.contentTitleHomeDashboard {
  padding: 30px;
  display: flex;
}

.titleHomeDashboard i {
  font-size: 40px;
  color: var(--branco);
}

.titleHomeDashboard h1 {
  font-size: 35px;
  color: var(--branco);
  margin-top: 1%;
}

.titleHomeDashboard p {
  font-size: 14px;
  color: var(--branco);
  margin-top: 3%;
  margin-right: 55%;
}

.buttonTitleDashboard {
  margin-top: 8%;
  display: flex;
  text-align: center;
}

.contentbuttonTitleDashboard {
  width: 200px;
  height: 40px;
  background: var(--branco);
  border-radius: 20px;
  padding: 3px;
}

.contentbuttonTitleDashboard a {
  color: #021338;
  color: var(--principal);
  font-size: 14px;
}

.contentbuttonTitleDashboard i {
  color: #021338;
  color: var(--principal);
  margin-left: 3%;
  margin-top: 5%;
  font-size: 14px;
  animation: descerAnimation .8s ease infinite;
}

@keyframes descerAnimation {
  100% {
    transform: translateY(20%);
    opacity: 0.8;
  }
}

.boxParteBeneficiosTitleHomeDashboard {
  width: 380px;
  height: 275px;
  border-radius: 20px;
  background: var(--branco);
}

.funcaoHomeDashboard {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.boxFuncaoHomeDashboard {
  background: #021338;
  background: var(--principal);
  border-radius: 25px;
  width: 900px;
  height: 1200px;
}

.titleBoxFuncaoHomeDashboard {
  padding: 30px;
  text-align: center;
}

.titleBoxFuncaoHomeDashboard h1 {
  color: var(--branco);
  font-size: 25px;
}


.titleBoxFuncaoHomeDashboard p {
  color: var(--branco);
  margin-top: 1%;
}

.funcaoBoxHomeDashboard {
  padding: 30px;
  margin-top: 2%;
}

.contentFuncaoBoxHomeDashboard {
  display: flex;
}

.funcaoBoxHomeDashboard h1 {
  font-size: 25px;
  color: var(--branco);
}

.videoFuncaoBoxHomeDashboard iframe {
  width: 450px;
  height: 250px;
}


.funcaoBoxHomeDashboard p {
  font-size: 14px;
  color: var(--branco);
  margin-right: 45%;
  margin-top: 5%;
}

.buttonFuncaoBoxHomeDashboard {
  margin-top: 5%;
}

.contentButtonFuncaoBoxHomeDashboard {
  width: 120px;
  height: 30px;
  background: var(--branco);
  border-radius: 20px;
  text-align: center;
}

.contentButtonFuncaoBoxHomeDashboard a {
  font-size: 14px;
  color: #021338;
  color: var(--principal);
  padding: 5px;
  margin-right: 0%
}

.contentButtonFuncaoBoxHomeDashboard i {
  font-size: 10px;
  color: #021338;
  color: var(--principal);
  padding: 5px;
  margin-left: -8%;
  margin-top: 4%;
  animation: FuncaoBoxHomeDashboard .8s ease infinite;
}

@keyframes FuncaoBoxHomeDashboard {
  100% {
    transform: translateX(10%);
  }
}

.primeirosPassosHomeDashboard {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.boxPrimeirosPassosHomeDashboard {
  width: 900px;
  height: 900px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
}

.titleBoxPrimeirosPassosHomeDashboard {
 padding: 30px;
 text-align: center;
}

.titleBoxPrimeirosPassosHomeDashboard h1 {
  color: var(--branco);
  font-size: 25px;
}


.titleBoxPrimeirosPassosHomeDashboard p {
  color: var(--branco);
  font-size: 14px;
}

@media (max-width: 991px) {

  .titleHomeDashboard {
    display: grid;
    justify-content: center;
  }

  .parteTitleHomeDashboard {
    text-align: center;
  }
  
  .boxTitleHomeDashboard {
    width: 320px;
    height: 650px;
  }

  .titleHomeDashboard h1 {
    font-size: 25px;
    margin-top: 5%;
  }

  .contentTitleHomeDashboard {
   padding: 30px;
    display: grid;
  }
  
  
  .titleHomeDashboard p {
    margin-right: 0%;
    margin-top: 5%;
  }
  
  .buttonTitleDashboard {
    margin-top: 10%;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  
  .contentbuttonTitleDashboard {
    width: 200px;
    height: 40px;
    background: var(--branco);
    border-radius: 20px;
    padding: 3px;
  }

  .boxFuncaoHomeDashboard {
    width: 420px;
    height: 2000px;
  }
  
  .boxParteBeneficiosTitleHomeDashboard {
    width: 260px;
    margin-top: 15%;
  }

  .titleBoxFuncaoHomeDashboard {
    text-align: center;
    padding: 50px;
  }


  .titleFuncaoBoxHomeDashboard {
    text-align: center;
  }

  .buttonFuncaoBoxHomeDashboard {
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    justify-content: center;
  }

  .titleFuncaoBoxHomeDashboard h1 {
    font-size: 20px;
    margin-right: 0%;
  }

  .titleFuncaoBoxHomeDashboard p {
    font-size: 12px;
    text-align: center;
    margin-left: 0%;
    transform: translateX(35%);
  }

  .contentFuncaoBoxHomeDashboard {
    display: grid;
    justify-content: center;
    margin-left: 0%;
    margin-right: 0%;
  }

  .videoFuncaoBoxHomeDashboard iframe {
    width: 280px;
    height: 230px;
    margin-top: 10%;
    border-radius: 20px;
  }

  .titleBoxFuncaoHomeDashboard p {
    font-size: 14px;
  }

  .boxPrimeirosPassosHomeDashboard {
    width: 1500px;
  }
}


/*Dados Dashboard*/

.visitasDashboard {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.boxVisitasDashboard {
  width: 800px;
  height: 600px;
  border-radius: 20px;
  background: #021338;
  background: var(--principal);
}

.boxVisitasDashboard-2 {
  width: 800px;
  height: 1200px;
  border-radius: 20px;
  background: #021338;
  background: var(--principal);
  margin-top: 6%;
}

.contentTitleBoxVisitasDashboard {
  padding: 35px;
  text-align: center;
}

.contentTitleBoxVisitasDashboard h1 {
  color: var(--branco);
  font-size: 20px;
}

.contentTitleBoxVisitasDashboard p {
  color: var(--branco);
  font-size: 12px;
  margin-top: 1%;
}

.contentBoxTitleBoxVisitas {
  display:grid;
  justify-content: center;
}

.boxTitleBoxVisitas {
  width: 500px;
  height: 300px;
  border-radius: 20px;
  background: var(--branco);
}


.boxTitleBoxVisitas iframe {
  width: 500px;
  height: 300px;
  border-radius: 20px;
}


.boxTitleBoxVisitas-2 {
  width: 500px;
  height: 980px;
  border-radius: 20px;
}


.boxTitleBoxVisitas-2 iframe {
  width: 500px;
  height: 300px;
  border-radius: 20px;
  margin-top: 3%;
}

.buttonPainelBoxVisitas {
  display: flex;
  justify-content: center;
  padding: 30px;
  text-align: center;
}

.contentButtonPainelBoxVisitas {
  width: 180px;
  height: 40px;
  border-radius: 20px;
  background: var(--branco);
  display: flex;
  justify-content: center;
}

.contentButtonPainelBoxVisitas a {
  padding: 5px;
  color: #021338;
  color: var(--principal);
  margin-right: 2%;
}

.contentButtonPainelBoxVisitas i {
  margin-top: 6%;
  color: #021338;
  color: var(--principal);
  font-size: 12px;
}

/*Download dashboard*/

.downloadAdmin {
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-top: 0%;
}

.contentDownloadAdmin {
  text-align: center;
  padding: 50px;
}

.titleDownloadAdmin h1 {
  color: #021338;
  color: var(--textP);
}

.titleDownloadAdmin p {
  color: #021338;
  color: var(--textP);
}

.classeBoxDownloadAdmin h1 {
  color: #fff;
  color: var(--text);
  font-size: 20px;
}

.contentClassesDownloadAdmin {
  margin-top: 5%;
  background: #021338;
  background: var(--principal);
  height: 40px;
  border-radius: 20px;
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBoxDownloadAdmin {
  display: grid;
  justify-content: center;
}

.parteBoxDownloadAdmin {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.boxDownloadAdmin {
  width: 330px;
  height: 480px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
  margin-right: 3%;
  display: flex;
  justify-content: center;
}

.configDashboard {
  display: none;
  margin-left: 10%;
  border-top: 1px solid var(--branco);
  transition: .8s ease;
}

.boxDownloadAdmin {
  display: grid;
  justify-content: center;
  padding: 20px;
}

.boxDownloadAdmin img {
  width: 230px;
  height: 230px;
  margin-left: 3%;
  margin-top: 1%;
}

.boxDownloadAdmin p {
  font-size: 10px;
  color: #fff;
  color: var(--text);
  text-align: left;
  margin-left: 3%;
}

.boxDownloadAdmin h1 {
  color: #fff;
  color: var(--text);
  text-align: left;
  font-size: 20px;
  margin-left: 3%;
  margin-top: 1%;
}


.button-box-download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  background: #fff;
  background: var(--bg);
  border-radius: 20px;
}

.button-box-download a {
  color: #021338;
  color: var(--textP);
  font-size: 14px;
}


@media (max-width: 600px) {
  .parteBoxDownloadAdmin {
    display: grid;
  }

  .contentClassesDownloadAdmin {
    width: 330px;
    height: 45px;
    margin-top: 10%;
  }

  .boxDownloadAdmin img {
    margin-left: 8%;
  }

  .boxDownloadAdmin h1 {
    margin-left: 8%;
  }

  .boxDownloadAdmin p {
    margin-left: 8%;
  }

  .button-box-download {
    margin-left: 5%;
  }

  .boxDownloadAdmin {
    margin-top: 5%;
  }
}

/*Portal do Contador*/

.portal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.content-title-vantagens-portal {
  display: flex;
  justify-content: center;
}

.vantagens-portal .img-vantagem-portal {
  width: 550px;
  height: 400px;
  padding: 20px;
}

.content-portal-title {
  display: flex;
  justify-content: center;
}

.title-portal {
  width: 1000px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title-portal h1 {
  color: #021338;
  color: var(--textP);
  font-size: 40px;
}

.title-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-top: 1%;
}

.content-vantagens-portal {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.title-vantagens-content-portal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-vantagens-portal {
  margin-left: 5%;
}

.title-vantagens-portal h1 {
  color: #021338;
  color: var(--textP);
  font-size: 30px;
}

.title-vantagens-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-top: 1%;
}

.content-box-vantagem-portal {
  display: flex;
  justify-content: center;
  margin-top: 0.5%;
}

.icon-box-vantagem-portal {
  width: 150px;
  height: 150px;
  margin-left: 3%;
}

.content-box-vantagem-portal {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}


.box-vantagem-portal {
  width: 400px;
  height: 180px;
  border-radius: 20px;
  margin-right: 2%;
  margin-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.box-vantagem-portal h1 {
  color: #021338;
  color: var(--textP);
  font-size: 25px;
}

.box-vantagem-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-top: 1%;
}

.box-vantagem-portal i {
  color: #021338;
  color: var(--textP);
  font-size: 30px;
  margin-right: 10%;
  margin-left: 5%;
}

.border-portal {
  width: 100%;
  height: 2px;
  background: #021338;
  background: var(--principal);
  margin-top: 3%;
}

.benefecios-portal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.benefecios-portal img {
  width: 650px;
  height: 550px;
  margin-left: 5%;
}

.content-beneficios-portal {
  display: grid;
  justify-content: center;
}

.content-vantagem-portal {
  display: flex;
  justify-content: center;
}

.box-benefecios-portal  {
  width: 500px;
  height: 150px;
  margin-top: 3%;
  margin-left: 1%;
  display: flex;
  justify-content: left;
  align-items: center;
}


.box-benefecios-portal:hover {
  width: 500px;
  height: 150px;
  margin-top: 3%;
  margin-left: 1%;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid #021338;
  border: 1.5px solid var(--principal);
  padding: 10px;
  transition: 2s ease;
}



.box-benefecios-portal  h1 {
  color: #021338;
  color: var(--textP);
  font-size: 35px;
}

.box-benefecios-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 16px;
  margin-top: 3%;
}

.logos-sistemas-portal {
  margin-top: 5%;
}

.logos-sistemas-portal h1 {
  color: #021338;
  color: var(--textP);
  text-align: center;
}

.content-logos-sistemas-portal {
  display: flex;
  justify-content: center;
  width: 50%;
  flex-wrap: wrap;
  margin-top: 3%;
}

.content-sistemas-portal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-sistemas-portal .img-sistema-portal {
  width: 800px;
  height: 500px;
}

.box-logo-sistema {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  border: 3px solid #021338;
  border: 3px solid var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  margin-right: 3%;
  padding: 20px;
}

.box-logo-sistema img {
  border-radius: 10px;
}

.about-portal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 30px;
}

.content-about-portal {
  margin-left: 3%;
}

.about-portal h1 {
  color: #021338;
  color: var(--textP);
  font-size: 40px;
}

.about-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 14px;
  margin-top: 1%;
  margin-right: 50%;
}



.about-portal img {
  width: 600px;
  height: 400px;
  margin-left: 0%;
}

.button-portal {
  width: 300px;
  height: 40px;
  background: #021338;
  background: var(--principal);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.button-portal a {
  color: #fff;
  color: var(--text);
  font-size: 12px;
  margin-right: 3%;
}

.button-portal i {
  color: #fff;
  color: var(--text);
  font-size: 8px;
}

@media(max-width: 1024px) {
  .content-box-vantagem-portal {
      display: grid;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
  }
  .content-beneficios-portal {
    display: grid;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .content-sistemas-portal {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .content-sistemas-portal .img-sistema-portal {
    width: 500px;
    height: 400px;
  }
  .logos-sistemas-portal {
    margin-top: 10%;
  }
  .content-logos-sistemas-portal {
    display: grid;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
    margin-top: 5%;
  }
  .border-portal {
    margin-top: 10%;
  }

  .benefecios-portal {
    margin-top: 10%;
  }

  .content-box-vantagem-portal {
    margin-top: 5%;
  }
  .box-logo-sistema {
    width: 150px;
    height: 150px;
    border-radius: 20px;
    background: #021338;
    background: var(--principal);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-right: 0%;
    padding: 20px;
  }
  .content-sistemas-portal .img-sistema-portal {
    width: 500px;
    height: 400px;
    margin-left: -5%;
    margin-top: 10%;
  }
  .about-portal {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 0%;
    padding: 110px;
  }
  .content-about-portal {
    margin-left: 0%;
    text-align: center;
    padding: 30px;
  }
  .about-portal p {
    color: #021338;
    color: var(--textP);
    font-size: 12px;
    margin-top: 1%;
    margin-right: 0%;
  }
  .about-portal img {
    width: 600px;
    height: 400px;
    margin-left: 0%;
  }
  .content-button-portal {
    display: flex;
    justify-content: center;
  }

  .content-img-about-portal {
    display: flex;
    justify-content: center;
  }
  .content-title-portal h1 {
    color: #021338;
    color: var(--textP);
  }
  
  .content-title-portal p {
    color: #021338;
    color: var(--textP);
  }
}


.Ebackup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-ebackup p {
  color: #021338;
  color: var(--textP);
  font-size: 14px;
}


.title-solicitar-portal h1 {
  color: #021338;
  color: var(--textP);
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px;
  background: #03588C;
}

.not-found h1 {
  color: #F0EC10;
  font-size: 140px;
}

.not-found h2 {
  color: #F0EC10;
  font-size: 40px;
}

.not-found p {
  color: #fff;
  color: var(--text);
  font-size: 14px;
}

.content-button-not-found {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.button-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: var(--bg);
  width: 250px;
  height: 40px;
  border-radius: 20px;
}

.button-not-found a {
  font-size: 12px;
  color: #03588C;
}

.animation-notfound i {
  color: #fff;
  color: var(--text);
  font-size: 60px;
  animation: animation-car 8s infinite;
}

.animation-notfound {
  margin-top: 5%;
}

.pista-animation {
  width: 100%;
  height: 50px;
  background: #2B354F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tracos-pista-animation-1 {
  width: 50px;
  height: 5px;
  background: #F0EC10;
  margin-right: 10%;
}

.tracos-pista-animation-2 {
  width: 50px;
  height: 5px;
  background: #F0EC10;
  margin-right: 10%;
}

.tracos-pista-animation-3 {
  width: 50px;
  height: 5px;
  background: #F0EC10;
  margin-right: 10%;
}

.tracos-pista-animation-4 {
  width: 50px;
  height: 5px;
  background: #F0EC10;
  margin-right: 10%;
}

.tracos-pista-animation-5 {
  width: 50px;
  height: 5px;
  background: #F0EC10;
}

@keyframes animation-car {
  0% {
    opacity: 0;
    transform: translateX(-500%) translateY(50%);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(450%) translateY(50%);
  }
}

.Ebackup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.title-ebackup {
  text-align: center;
  margin-top: 1%;
}

.title-ebackup h1 {
  color: #021338;
  color: var(--textP);
  font-size: 40px;
}

.img-title-ebackup {
  width: 400px;
  height: 400px;
}

.content-img-title-ebackup {
  display: flex;
  justify-content: center;
}

.solucao-ebackup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.title-solucao-ebackup h1 {
  color: #021338;
  color: var(--textP);
  font-size: 20px;
}

.title-solucao-ebackup p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-right: 60%;
}

.solucao-ebackup img {
  width: 400px;
  height: 350px;
  margin-left: -40%;
}

.topicos-ebackup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-topico-ebackup {
  margin-top: 3%;
}

.box-topico-ebackup h1 {
  color: #021338;
  color: var(--textP);
  font-size: 35px;
}

.box-topico-ebackup p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
}

.topicos-ebackup img {
  width: 400px;
  height: 400px;
  margin-left: 3%;
  margin-top: 3%;
}

@media(max-width: 600px) {
  .solucao-ebackup {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 150px;
}

.title-solucao-ebackup {
  text-align: center;
}

.title-solucao-ebackup h1 {
  font-size: 15px;
}

.title-solucao-ebackup p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-right: 0%;
}

.content-img-solucao-ebackup {
  display: flex;
  justify-content: center;
}

.solucao-ebackup  img {
  width: 330px;
  height: 200px;
  margin-left: 0%;
  margin-top: 10%;
}

.topicos-ebackup {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.topicos-ebackup p {
  font-size: 12px;
}

.content-box-topico-ebackup {
  margin-top: 10%;
}

.content-img-solucao-ebackup {
  display: flex;
  justify-content: center;
}

.topicos-ebackup img {
  width: 300px;
  height: 300px;
  margin-top: 10%;
}

.box-topico-ebackup {
  margin-top: 10%;
}

}

@media(max-width: 600px) {

  .benefecios-portal {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

.benefecios-portal img {
  width: 300px;
  height: 250px;
  margin-top: 10%;
}


  .title-portal {
    width: 335px;
    height: 200px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.content-title-portal h1 {
  color: #021338;
  color: var(--textP);
}

.content-title-portal p {
  color: #021338;
  color: var(--textP);
}

.box-vantagem-portal {
    width: 330px;
    height: 180px;
    border-radius: 20px;
    margin-right: 3%;
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.box-benefecios-portal {
  width: 330px;
  height: 150px;
  border-radius: 20px;
  background: #021338;
  background: var(--principal);
  margin-top: 3%;
  margin-left: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logos-sistemas-portal h1 {
  color: #021338;
  color: var(--textP);
  text-align: center;
  font-size: 20px;
}

.about-portal h1 {
  color: #021338;
  color: var(--textP);
  font-size: 25px;
}



  .content-box-vantagem-portal {
      display: grid;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
  }
  .content-beneficios-portal {
    display: grid;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .content-sistemas-portal {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .content-sistemas-portal .img-sistema-portal {
    width: 400px;
    height: 300px;
    margin-top: 10%;
    margin-left: 1%;
}
  .logos-sistemas-portal {
    margin-top: 10%;
  }
  .content-logos-sistemas-portal {
    display: grid;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
    margin-top: 5%;
  }
  .border-portal {
    margin-top: 10%;
  }

  .benefecios-portal {
    margin-top: 10%;
  }

  .content-box-vantagem-portal {
    margin-top: 5%;
  }
  .box-logo-sistema {
    width: 150px;
    height: 150px;
    border-radius: 20px;
    background: #021338;
    background: var(--principal);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-right: 0%;
    padding: 20px;
  }
  .content-sistemas-portal .img-sistema-portal {
    width: 500px;
    height: 400px;
    margin-left: -5%;
    margin-top: 10%;
  }
  .about-portal {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 0%;
    padding: 50px;
  }
  .content-about-portal {
    margin-left: 0%;
    text-align: center;
    padding: 0%;
}

  .about-portal img {
    width: 400px;
    height: 300px;
    margin-left: 0%;
}
.descricao-about-portal {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.about-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-top: 1%;
  margin-right: 0%;
  width: 85%;
}

  .content-button-portal {
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  .img-sistema-portal {
    padding: 50px;
  }

  .title-portal h1 {
    color: #021338;
    color: var(--textP);
    font-size: 30px;
}

.title-portal p {
  color: #021338;
  color: var(--textP);
  font-size: 10px;
  margin-top: 1%;
}

.title-vantagens-portal {
  padding: 80px;
  text-align: center;
  margin-top: -5%;
}

.content-box-vantagem-portal {
  margin-top: -1%;
}

.title-vantagens-content-portal {
  display: grid;
  justify-content: center;
  align-items: center;
}


.vantagens-portal .img-vantagem-portal {
  width: 300px;
  height: 250px;
  padding: 20px;
}

.box-benefecios-portal h1 {
  color: #fff;
  color: var(--text);
  font-size: 20px;
}

.box-benefecios-portal p {
  color: #fff;
  color: var(--text);
  font-size: 10px;
  margin-top: 3%;
}

.title-vantagens-portal h1 {
  font-size: 15px;
}

.content-img-title-vantagens-portal {
  display: flex;
  justify-content: center;
}

.title-vantagens-portal {
  margin-left: 0%;
}

.title-vantagens-portal p {
  font-size: 10px;
}

  .content-img-about-portal {
    display: flex;
    justify-content: center;
  }

  .box-benefecios-portal {
    background: #fff;
    background: var(--bg);
}

.box-benefecios-portal h1 {
  color: #021338;
  color: var(--textP);
}

.box-benefecios-portal p {
  color: #021338;
  color: var(--textP);
}

.box-logo-sistema {
  background: #fff;
  background: var(--bg);
}

.img-sistema-portal {
  width: 300px;
  height: 200px;
}

.content-img-title-ebackup img {
  width: 300px;
  height: 300px;
}

.content-descricao-title-ebackup {
  display: flex;
  justify-content: center;
}

.solucao-ebackup {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.title-ebackup p {
  color: #021338;
  color: var(--textP);
  width: 95%;
  font-size: 10px;
}


}

.popup-portal {
  padding: 30px;
}

.title-popup-portal h1 {
  font-size: 40px;
  color: #021338;
  color: var(--textP);
  text-align: center;
}

.title-popup-portal p {
  font-size: 14px;
  color: #021338;
  color: var(--textP);
}

.lista-popup-portal {
  margin-top: 3%;
}

.lista-popup-portal p {
  font-size: 12px;
  color: #021338;
  color: var(--textP);
}

.aviso-final-popup-portal {
  margin-top: 3%;
}

.aviso-final-popup-portal p {
  font-size: 14px;
  color: #021338;
  color: var(--textP);
}

.button-popup-portal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  background: #021338;
  background: var(--principal);
  margin-top: 3%;
  border-radius: 20px;
}

.button-popup-portal a {
  font-size: 12px;
  outline: none;
}

.about-portal-popup {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.about-portal-popup p {
  font-size: 12px;
  color: #021338;
  color: var(--principal);
  font-weight: bolder;
  animation: alert-popup 3s ease infinite;
}

@keyframes alert-popup {
  100% {
    color: #BB2020;
  }
}

.tecnologias-footer {
  margin-right: 10%;
}

.endereco-footer-tecnologias p {
  font-size: 12px;
  margin-top: 1%;
}

.blog {
  padding: 50px;
}

.content-blog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
  margin-top: 5%;
}

.box-blog {
  width: 350px;
  padding: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.title-blog {
  text-align: center;
}

.title-blog h1 {
  color: #021338;
  color: var(--textP);
  font-size: 30px;
}

.title-blog p {
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-top: 1%;
}

.img-box-blog {
  width: 330px;
  height: 330px;
  position: relative;
}

.title-box-blog {
  text-align: left;
  color: #021338;
  color: var(--textP);
  font-size: 23px;
  margin-top: 5%;
}

.descricao-box-blog {
  text-align: left;
  color: #021338;
  color: var(--textP);
  font-size: 14px;
  margin-top: 1%;
}

.data-box-blog {
  text-align: left;
  color: #021338;
  color: var(--textP);
  font-size: 12px;
  margin-top: 3%;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 100vh;
}

.details img {
  width: 600px;
  height: 500px;
  margin-left: 10%;
  margin-right: 5%;
}

.content-details h1 {
  color: #021338;
  color: var(--textP);
  font-size: 35px;
}

.content-blog {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 3%;
}

.content-box-blog {
  margin-right: 3%;
}

.popup-home-img-pc {
  width: 100%;
}

.popup-home-img-mobile {
  display: none;
}

.content-button-popup {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.popup-mobile {
  display: none;
}

.popup-pc {
  padding: 10px;
}

.button-popup-home {
  width: 50%;
  height: 40px;
  background: #021338;
  background: var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.button-popup-home a {
  color: #fff;
  font-size: 12px;
  outline: none
}

.content-button-popup-2 {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.content-button-popup-2 a {
  color: #021338;
  color: var(--textP);
  text-align:center;
  font-size:12px
}

@media (max-width: 991px) {
  .popup-content {
    width: 90%;
  }
  .popup-pc {
    display: none;
  }
  .popup-mobile {
    display: block;
  }
  .popup-home-img-mobile {
    display: block;
    width: 100%;
    height: 100%;
  }
  .popup-mobile {
    padding: 20px;
  }
  .button-close-popup {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 35px;
    padding: 20px;
    color: #021338;
    color: var(--textP);
  }
}

.content-box-insta {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.box-insta {
  width: 500px;
  margin-right: 50px;
  margin-top: 3%;
}

.box-insta img {
  width: 500px;
  height: 500px;
}

.content-box-insta-center {
  display: flex;
  justify-content: center;
}

.content-button-insta {
  width: 60%;
  height: 50px;
  border-radius: 30px;
  background: #021338;
  background: var(--principal);
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
